import React, { useContext, useState } from "react";
import DashboardLayout from "../../layout/dashboard";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { circlePattern, communityImg2, deleteIcon } from "../../images";
import { BiSolidCoinStack } from "react-icons/bi";
import { FaCheck, FaPlus } from "react-icons/fa";
import {
  httpGetWithTokenRest,
  httpPatch2WithTokenRest,
  httpPatchWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import moment from "moment/moment";
import { capitalizeFirstWord, sortArrayByKey } from "../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { communityRoute } from "../../utils/allRoutes";
import { MultiSelect } from "chakra-multiselect";
import ls from "localstorage-slim";
import { AppContext } from "../../redux/appContext";
import CoCaregiverWhoModal from "./selecte_cocaregiver";
import WhoModal from "../tasks/who_modal";
import GroupCreatedModal from "./group_created_modal";
import CaregiverListModal from "./all_caregivers_modal";
import GroupEditedModal from "./group_edited_modal";
import GroupRemoveModal from "./remove_member_modal";
import GroupMemberAdded from "./added_modal";
import GroupMemberRemoved from "./group_member_removed";
import CarecircleInfoModal from "./carecircle_info_modal";
import InviteCareGiverModal from "../dashboard/co_caregiver_modal";
import CareCircleCard from "./carecircle_card";

export default function Community() {
  const { subscription } = useContext(AppContext);
  const userPermission = ls.get("sj_cocg_permission", { decrypt: true });
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user.tag !== "cg" && user.caregiver_type != "primary caregiver";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [description, setDescription] = useState("")
  const toast = useToast();
  const {
    isOpen: isOpenInterest,
    onOpen: onOpenInterest,
    onClose: onCloseInterest,
  } = useDisclosure();

  const {
    isOpen: isOpenCreated,
    onOpen: onOpenCreated,
    onClose: onCloseCreated,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleted,
    onOpen: onOpenDeleted,
    onClose: onCloseDeleted,
  } = useDisclosure();

  const {
    isOpen: isOpenEdited,
    onOpen: onOpenEdited,
    onClose: onCloseEdited,
  } = useDisclosure();

  const {
    isOpen: isOpenMembers,
    onOpen: onOpenMembers,
    onClose: onCloseMembers,
  } = useDisclosure();
  const {
    isOpen: isOpenCoGiverModal,
    onOpen: onOpenCoGiverModal,
    onClose: onCloseCoGiverModal,
  } = useDisclosure();
  const {
    isOpen: isOpenInfo,
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
  } = useDisclosure();
  const {
    isOpen: isOpenAdded,
    onOpen: onOpenAdded,
    onClose: onCloseAdded,
  } = useDisclosure();

  const {
    isOpen: isOpenModalWho,
    onOpen: onOpenModalWho,
    onClose: onCloseModalWho,
  } = useDisclosure();

  const {
    isOpen: isOpenModalJorner,
    onOpen: onOpenModalJorner,
    onClose: onCloseModalJorner,
  } = useDisclosure();

  const {
    isOpen: isOpenCommunity,
    onOpen: onOpenCommunity,
    onClose: onCloseCommunity,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateCommunity,
    onOpen: onOpenCreateCommunity,
    onClose: onCloseCreateCommunity,
  } = useDisclosure();
  const navigate = useNavigate();
  const [allInterest, setAllInterest] = React.useState([]);
  const [myInterest, setMyInterest] = React.useState([]);
  const [allInterestObj, setAllInterestObj] = React.useState([]);
  const [selectedInterest, setSelectedInterest] = React.useState([]);
  const [myCommunities, setMyCommunities] = React.useState([]);
  const [caregiverGroups, setCaregiverGroups] = React.useState([]);
  const [caregiverGroupsConstants, setCaregiverGroupsConstants] = React.useState([]);
  const [privateCommunity, setPrivateCommunity] = React.useState([]);
  const [allCommunities, setAllCommunities] = React.useState([]);
  const [isLoadingUpdateInterest, setIsLoadingUpdateInterest] =
    React.useState(false);
  const [isLoadingJoinCommunity, setIsLoadingJoinCommunity] =
    React.useState(false);
  const [selectedCommunity, setSelectedCommunity] = React.useState(null);
  const [selectedJorners, setSelectedJorner] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);

  const [toDelete, setToDelete] = React.useState(null);
  const [activeGroup, setActiveGroup] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [jorners, setJorners] = React.useState([]);
  const [selectedCaregivers, setselectedCaregivers] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [selectedCaregiversName, setselectedCaregiversName] = React.useState(
    []
  );

  const [coCaregivers, setCoCaregivers] = useState([]);
  const [availableCCO, setAvailableCCO] = useState([]);
  const [inviteGiversConstants, setInviteGiversConstants] = useState([]);

  const [loading, setLoading] = React.useState(true);
  const [selectedCreateInterest, setSelectedCreateInterest] = React.useState(
    []
  );



  const fetchAllInterests = async () => {
    const resp = await httpGetWithTokenRest("group-interests");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setAllInterest(resp.data);
      const allInt = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];
        let data = {
          id: element,
          value: element,
        };
        allInt.push(data);
      }
      setAllInterestObj(allInt);
    }
  };

  const fetchMyInterests = async () => {
    const resp = await httpGetWithTokenRest("caregivers/interest");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setMyInterest(resp.data?.interest);
    }
  };

  const fetchMyCommunities = async () => {
    const resp = await httpGetWithTokenRest("communities/me");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const d = resp.data;
      var publicCommunity = d.filter((c) => c.community_type === "public");
      var pCommunity = d.filter((c) => c.community_type === "private");
      // setMyCommunities(publicCommunity);
      setPrivateCommunity(pCommunity);
      setLoading(false);
    }
  };

  const fetchAllCommunities = async () => {
    const resp = await httpGetWithTokenRest("communities");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setAllCommunities(resp.data.rows);
    }
  };
  const fetchCarecircleGroups = async () => {
    const resp = await httpGetWithTokenRest("care_circle/me");
    if (resp.error !== null && resp.error !== undefined) {
    } else {
      const d = resp.data;
      var pCommunity = d.filter((c) => c.community_type === "private");
      setCaregiverGroups(pCommunity);
      setCaregiverGroupsConstants(pCommunity)
      setLoading(false);
    }
  };

  const onSelectInterest = (e) => {
    setSelectedInterest(e);
  };

  const updateCareGiverInterest = async () => {
    if (selectedInterest.length < 1) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `Please select at least one interest`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsLoadingUpdateInterest(true);
      const data = {
        interest: [...selectedInterest],
      };
      const resp = await httpPatch2WithTokenRest("interest", data);
      if (resp.error !== null && resp.error !== undefined) {
        setIsLoadingUpdateInterest(false);

        return toast({
          title: "Sorry, we hit a snag!",
          description: `${resp.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        fetchAllCommunities();
        onCloseInterest();
        fetchMyInterests();
        toast({
          title: "Success",
          description: `Interest updated successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoadingUpdateInterest(false);
    }
  };

  const handleJoinCommunity = async () => {
    setIsLoadingJoinCommunity(true);
    const resp = await httpPatch2WithTokenRest(
      `communities/join/${selectedCommunity?.id}`,
      {}
    );
    if (resp.error !== null && resp.error !== undefined) {
      setIsLoadingJoinCommunity(false);
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchMyCommunities();
      onCloseCommunity();
      setIsLoadingJoinCommunity(false);
      navigate(`${communityRoute}/${selectedCommunity?.id}`);
    }
  };

  const handleCommunityClick = (community) => {
    setSelectedCommunity(community);
    onOpenCommunity();
  };
  const addMembers = async (v) => {
    setLoading(true);
    let resp = await httpPatch2WithTokenRest("/care_circle/members/add", {
      co_caregiver_id: v,
      community_id: activeGroup ? activeGroup.id : "",
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchMyCommunities();
      fetchCarecircleGroups();
      onCloseCreateCommunity();
      setActiveGroup(null);
      setMembers([]);
      onOpenAdded();
    }
    setLoading(false);
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    if (!selectedJorners) {
      return toast({
        title: "All fields are required!",
        description: `Please select a Jorner`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (selectedCaregivers.length == 0) {
      return toast({
        title: "All fields are required!",
        description: `Please select Caregivers`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    // if (selectedCreateInterest.length < 1) {
    //   return toast({
    //     title: "Sorry, we hit a snag!",
    //     description: `Please select an interest`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
    if(description.length > 200) {
      return toast({
        title: "Description should be maximum of 200 characters",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }
    values.description = description;
    values.interest = ["group"];
    values.size = 1;
    values.created_by_tag = "caregiver";
    values.jorner = selectedJorners.id;
    values.co_caregivers = selectedCaregivers;
    let resp;

    if (isEdit) {
      resp = await httpPatchWithTokenRest(
        "care_circle/" + activeGroup?.id,
        values
      );
    } else {
      resp = await httpPostWithToken("care_circle", values);
    }

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchMyCommunities();
      fetchCarecircleGroups();
      onCloseCreateCommunity();
      setselectedCaregivers([]);
      setselectedCaregiversName([]);
      setSelectedJorner(null)
      setValue("name", "")
      setValue("description", "")
      
      if (isEdit) {
        onOpenEdited();
      } else {
        onOpenCreated();
      }
    }
  }


  const getCocaregivers = async () => {
    var j = await httpGetWithTokenRest("co_caregiver");
    if (j.status == "success") {
      var user = JSON.parse(sessionStorage.getItem("sj_user"));
      let d = j.data;
      d = d.filter((c) => c.id !== user.id);
      // d = [...d, user];
      setCoCaregivers(d);
    }
  };
  const getJorners = async () => {
    var j = await httpGetWithTokenRest("co_caregiver");
    var url;
    if (user.tag == "cocg") {
      url = `co_caregiver/${user.id}/jorners`;
    } else {
      url = `jorner`;
    }
    var j = await httpGetWithTokenRest(url);
    if (j.status == "success") {
      const jor = j.data;
      const jornersss = jor.map((item, i) => {
        return {
          id: i,
          key: i,
          label: item.first_name + " " + item.last_name,
          value: item.id,
          ...item,
        };
      });
      setJorners(jornersss);
    }
    // setJorners(j.data);
  };
  const fetchCaregiverconstants = async () => {
    var j = await httpGetWithTokenRest("permissions");
    if (j.status == "success") {
      setInviteGiversConstants(j.data.permissions);
    }
  };
  const deleteMember = async (cm, member) => {
    let resp = await httpPatch2WithTokenRest("/care_circle/members/add", {
      co_caregiver_id: [member],
      community_id: cm,
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchCarecircleGroups();
    }
  };

  React.useEffect(() => {
    // fetchAllInterests();
    getCocaregivers();
    fetchMyCommunities();
    // fetchAllCommunities();
    getJorners();
    fetchCarecircleGroups();
    // fetchMyInterests();
    fetchCaregiverconstants();
  }, []);

  return (
    <DashboardLayout
      element={
        <Box p={5} bg={"#FAF8FF"}>
          <Flex justifyContent={"space-between"} display={{base : "flex", md : "none"}}>
          <Text fontSize={24} fontWeight={600}>
              Care Circle
            </Text>
            {isCoCaregiver ? (
                  <></>
                ) : (
                  <Button
                    size={"md"}
                    w={{base : "50px", md : "100%"}}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    onClick={() => {
                      if (subscription.care_circle != "unlimited") {
                        const sComm = parseInt(subscription.care_circle);
                        if (caregiverGroupsConstants.length >= sComm) {
                          return toast({
                            title: "Access Denied!",
                            description: `Please upgrade your account to create more Communities`,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }
                      }
                      onOpenCreateCommunity();
                    }}
                  >
                    <Flex alignContent={"center"}>
                      <MdAdd color="#fff" />
                      <Text display={{base : "none", md : "block"}} color={"#fff"} fontSize={12} fontWeight={500}>
                        Create a Group
                      </Text>
                    </Flex>
                  </Button>
                )}
          </Flex>
          <Flex>
            <Text display={{base : "none", md : "block"}} fontSize={24} fontWeight={600}>
              Care Circle
            </Text>
            <Spacer display={{base : "none", md : "block"}} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex alignContent={"center"}>
                <FormControl isInvalid={errors.keyword} fontWeight={600} mr={2}>
                  <InputGroup bg={"#fff"} 
                      borderRadius={100}
                      minW={{ base: "100%", md: "250px" }}
                      >
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      fontSize={12}
                      fontWeight={400}
                      id="keyword"
                      minW={{ base: "100%", md: "250px" }}
                      borderRadius={100}
                      placeholder="e.g. Search for a care circle
                      ..."
                      borderColor={"#E3E4E8"}
                      onChange={(e)=> {
                        let v = e.target.value;
                        if(v !== "") {
                          let cs = caregiverGroupsConstants.filter((c)=> c.name.toLowerCase().includes(v.toLowerCase()))
                          setCaregiverGroups(cs)
                        }else{
                          setCaregiverGroups(caregiverGroupsConstants)
                        }
                      }}
                    />
                  </InputGroup>

                  <FormErrorMessage>
                    {/* {errors.keyword && errors.keyword.message} */}
                  </FormErrorMessage>
                </FormControl>
                {isCoCaregiver ? (
                  <></>
                ) : (
                  <Button
                    size={"md"}
                    w={{base : "50px", md : "100%"}}
                    color="#fff"
                    display={{base : "none", md : "block"}}
                    borderRadius={64}
                    background={"primary"}
                    onClick={() => {
                      if (subscription.care_circle != "unlimited") {
                        const sComm = parseInt(subscription.care_circle);
                        if (caregiverGroupsConstants.length >= sComm) {
                          return toast({
                            title: "Access Denied!",
                            description: `Please upgrade your account to create more Communities`,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }
                      }
                      onOpenCreateCommunity();
                    }}
                  >
                    <Flex alignContent={"center"}>
                      <MdAdd color="#fff" />
                      <Text display={{base : "none", md : "block"}} color={"#fff"} fontSize={12} fontWeight={500}>
                        Create a Group
                      </Text>
                    </Flex>
                  </Button>
                )}
              </Flex>
            </form>
          </Flex>

          <Stack w={"100%"}>
            {loading && (
              <Stack mt={5}>
                <Skeleton startColor="#808080" height="50px" />
                <Skeleton startColor="#808080" height="50px" />
                <Skeleton startColor="#808080" height="50px" />
              </Stack>
            )}
            {!loading && caregiverGroupsConstants.length < 1 ? (
              <Container
                maxW="md"
                width={{ base: "auto", md: "100%" }}
                textAlign={"center"}
              >
                <Center>
                  <Image src={"/cm.png"} />
                </Center>
                <Text mb={2} fontSize={18} fontWeight={600}>
                  Let's build your care team
                </Text>
                <Text fontSize={16} fontWeight={400} color={"#747A8B"}>
                  Your care team make up your jorner’s secondary caregivers and
                  they include - Your Nanny, Family, Friends, etc.
                </Text>
                {isCoCaregiver ? (
                  <></>
                ) : (
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    onClick={()=> {
                      if (subscription.care_circle != "unlimited") {
                        const sComm = parseInt(subscription.care_circle);
                        if (caregiverGroupsConstants.length >= sComm) {
                          return toast({
                            title: "Access Denied!",
                            description: `Please upgrade your account to create more Communities`,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }
                      }
                      onOpenCreateCommunity();
                    }}
                  >
                    Create a Group
                  </Button>
                )}

                <Text
                  color={"primary"}
                  textDecoration={"underline"}
                  fontSize={14}
                  fontWeight={600}
                  cursor={"pointer"}
                  onClick={onOpen}
                >
                  Understand How Sojorne Care Circle Group Works
                </Text>
              </Container>
            ) : (
              <>
                {caregiverGroups.length > 0 ? (
                  <Box>
                    <Text fontSize={16} fontWeight={600} my={4}>
                      Care Circle Groups
                    </Text>
                    <SimpleGrid gap={3} columns={{ base: 1, md: 1, lg: 3 }}>
                      {caregiverGroups.map((community, i) => (
                        <CareCircleCard key={community.id} community={community} />
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <>
                  {!loading && <Text>No carecircle found</Text>}
                  </>
                )}
              </>
            )}
          </Stack>

          {/* Task modal   */}
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"xl"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Heading textAlign={"center"} color={"#FF6E57"} fontSize={20}>
                  How it works
                </Heading>
                <TaskItem
                  img={"/cm1.png"}
                  title={"Navigate to the “Care Circle”"}
                  subtitle={
                    "Find the “Quick Action Section” on the dashboard and click Care Circle. Create a team if you’re new."
                  }
                />
                <TaskItem
                  img={"/cm2.png"}
                  title={"Invite Co-caregivers"}
                  subtitle={
                    "Tap 'Invite Co-Caregiver to begin assembling your support network. Invite family members, friends, or trusted caregivers by entering their email addresses and phone numbers."
                  }
                />
                <TaskItem
                  img={"/cm3.png"}
                  title={"Assign roles or permissions"}
                  subtitle={
                    "Assign roles or permissions to each member based on their involvement or responsibilities. Ensure that 'Community' is checked when inviting co-caregivers to the care circle."
                  }
                />
                <TaskItem
                  img={"/cm4.png"}
                  title={"Collaborate & Share"}
                  subtitle={
                    "Share updates, tasks, and schedules within the circle, collaborate efficiently by assigning tasks, sharing important information, and keeping everyone in the loop."
                  }
                />
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* interest modal */}
          <Modal
            isOpen={isOpenInterest}
            onClose={onCloseInterest}
            size={"2xl"}
            isCentered
            closeOnOverlayClick={false}
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Box textAlign={"center"}>
                  <Text fontSize={32} fontWeight={600} mb={5}>
                    What are you interested in?
                  </Text>
                  <Text fontSize={16} fontWeight={500} color={"secondary"}>
                    Choose 5 or more
                  </Text>
                  <Box mt={5}>
                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                      {allInterest.map((e, i) => (
                        <CustomCheckBox
                          key={i}
                          item={e}
                          allData={selectedInterest}
                          onSelectInterest={onSelectInterest}
                          isSelected={selectedInterest.includes(e)}
                        />
                      ))}
                    </SimpleGrid>
                  </Box>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  isLoading={isLoadingUpdateInterest}
                  onClick={updateCareGiverInterest}
                  disabled={selectedInterest.length < 1}
                >
                  Next
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* selected community modal */}
          <Modal
            isOpen={isOpenCommunity}
            onClose={onCloseCommunity}
            size={"xl"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Flex flexWrap={"wrap"} mb={3} textAlign={"center"}>
                  {selectedCommunity?.interest?.map((e, i) => (
                    <Text
                      key={i}
                      fontSize={12}
                      fontWeight={500}
                      textAlign={"center"}
                    >
                      {e} .
                    </Text>
                  ))}
                </Flex>
                <Text
                  fontSize={18.9}
                  fontWeight={600}
                  mb={3}
                  textAlign={"center"}
                >
                  {selectedCommunity?.name}
                </Text>
                <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
                  Description:
                </Text>
                <Text fontSize={12} fontWeight={400} mb={3}>
                  {selectedCommunity?.descriptions}
                </Text>
                <Flex>
                  <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
                    Members:
                  </Text>
                  <Spacer />
                  <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
                    Last seen:
                  </Text>
                </Flex>
                <Flex
                  alignItems={"center"}
                  display={{ base: "block", md: "flex" }}
                >
                  <AvatarGroup size="sm" max={3}>
                    {selectedCommunity?.members.map((e, i) => (
                      <Avatar key={i} name={e?.first_name} src={e?.avatar} />
                    ))}
                  </AvatarGroup>
                  <span>members</span>
                  <Spacer />
                  <Button
                    mt={2}
                    mb={4}
                    size={"sm"}
                    minW={"80px"}
                    color="#fff"
                    borderRadius={64}
                    background={"#E7FEEE"}
                    border={"1px solid #0CCA4A"}
                  >
                    <Text fontSize={12} fontWeight={500} color={"#099A38"}>
                      {moment(
                        selectedCommunity?.last_seen,
                        "YYYYMMDD"
                      ).fromNow()}
                    </Text>
                  </Button>
                </Flex>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  isLoading={isLoadingJoinCommunity}
                  onClick={handleJoinCommunity}
                >
                  Join Community
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
          <GroupCreatedModal
            isOpen={isOpenCreated}
            onClose={onCloseCreated}
            onFinished={() => {
              onCloseCreated();
              onCloseCreateCommunity();
            }}
          />

          <GroupMemberAdded
            isOpen={isOpenAdded}
            onClose={onCloseAdded}
            onFinished={() => {
              onCloseAdded();
              onCloseCreateCommunity();
            }}
          />
          <GroupMemberRemoved
            isOpen={isOpenDeleted}
            onClose={onCloseDeleted}
            onFinished={() => {
              onCloseDeleted();
            }}
          />

          <GroupRemoveModal
            isOpen={isOpenDelete}
            onClose={onCloseDelete}
            onFinished={(v) => {
              onCloseDelete();
              if (v == "close") {
                onOpenMembers();
              } else {
                onCloseDelete();
                onOpenDeleted();
                setMembers([]);
              }
            }}
            cm={activeGroup ? activeGroup.id : ""}
            member={toDelete}
          />

          <GroupEditedModal
            isOpen={isOpenEdited}
            onClose={onCloseEdited}
            onFinished={() => {
              onCloseEdited();
              onCloseCreateCommunity();
            }}
          />

          <CaregiverListModal
            isOpen={isOpenMembers}
            onClose={onCloseMembers}
            onFinished={(v, y) => {
              if (v == "add") {
                onOpenModalWho();
                onCloseMembers();
              } else if (v == "close") {
                setMembers([]);
                onCloseMembers();
              } else if (v == "delete") {
                setToDelete(y);
                onCloseMembers();
                onOpenDelete();
              }
            }}
            title={activeGroup ? activeGroup.name : ""}
            constants={activeGroup ? members : []}
          />
          <InviteCareGiverModal
            coGiverConstants={inviteGiversConstants}
            onClose={()=> {
              onCloseCoGiverModal();
              onOpenModalWho();
            }}
            isOpen={isOpenCoGiverModal}
            jorners={jorners}
            community={activeGroup ? activeGroup.id : ""}
            callback={()=> {
              getCocaregivers();
              onOpenCreateCommunity();
            }}
          />
          <CarecircleInfoModal
            isOpen={isOpenInfo}
            onClose={onCloseInfo}
            onFinished={(v) => {
              onCloseInfo();
              if (v == "view") {
                let community = activeGroup;
                let c = [];
                let mem = [];

                mem = community.members;
                for (let i = 0; i < coCaregivers.length; i++) {
                  const co = coCaregivers[i];
                  let isExist = community.members.find((l) => l.id == co.id);
                  if (!isExist) {
                    c = [...c, co];
                  } else {
                  }
                }
                let isExist = null;
                for (let i = 0; i < mem.length; i++) {
                  const m = mem[i];
                  isExist = coCaregivers.find((l) => l.id == m.id);
                  if (isExist) {
                    isExist.cm_tag = isExist.caregiver_type;
                    mem[i] = isExist;
                  }
                }
                setAvailableCCO(c);
                setMembers(mem);
                onOpenMembers();
                setActiveGroup(community);
              }

              if (v == "chat") {
                navigate(`/care-circle/${activeGroup.id}`);
              }

              if (v == "invite") {
                onOpenCoGiverModal();
              }
              setSelectedJorner(null);
            }}
            title={activeGroup ? activeGroup.name : ""}
            constants={activeGroup ? members : []}
            jorner={selectedJorners}
          />
          <WhoModal
            title={"Co-Caregiver"}
            buttonTitle={"Proceed"}
            isOpen={isOpenModalWho}
            onFinished={(v) => {
              if (v == "close") {
                if (members.length > 0) {
                  onOpenMembers();
                } else {
                  onOpenCreateCommunity();
                }
                onCloseModalWho();
              } else if (v == "invite") {
                onCloseModalWho();
                onOpenCoGiverModal();

              } else {
                let selected = [];
                for (let i = 0; i < v.length; i++) {
                  const s = v[i];
                  let cocg = coCaregivers.find((c) => c.id == s);
                  if (cocg) {
                    selected = [
                      ...selected,
                      `${cocg.first_name} ${cocg.last_name}`,
                    ];
                  }
                }
                if (members.length > 0) {
                  console.log(v);
                  if (v && v.length > 0) addMembers(v);
                } else {
                  setselectedCaregivers(v);
                  setselectedCaregiversName(selected);
                  onOpenCreateCommunity();
                }
              }
            }}
            source="community"
            constants={coCaregivers}
            selectedOriginal={selectedCaregivers}
            onClose={onCloseModalWho}
            activeList={members}
            invite={false}
          />
          <CoCaregiverWhoModal
            title={"Jorners"}
            isOpen={isOpenModalJorner}
            onFinished={(v) => {
              if (v == "close") {
                onOpenCreateCommunity();
                onCloseModalJorner();
              } else {
                setSelectedJorner(v);
                onOpenCreateCommunity();
              }
            }}
            source="jorner"
            constants={jorners}
            selectedOriginal={[]}
            onClose={onCloseModalJorner}
          />
          {/* create community modal */}
          <Modal
            isOpen={isOpenCreateCommunity}
            onClose={() => {
              onCloseCreateCommunity();
              setIsEdit(false);
              setMembers([]);
              setSelectedGroup(null);
              setActiveGroup(null);
              setValue("name", "");
              setValue("description", "");
              setSelectedJorner(null);
              setselectedCaregivers([]);
              setselectedCaregiversName([]);
            }}
            size={"2xl"}
            isCentered
            closeOnOverlayClick={false}
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader>
                {isEdit
                  ? capitalizeFirstWord(
                      "Edit " + activeGroup?.name + " Care Circle Group"
                    )
                  : "Care Circle Group"}
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {!isEdit && (
                    <FormControl mt={3}>
                      <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                        Jorner
                      </FormLabel>
                      <InputGroup>
                        <Input
                          cursor={"pointer"}
                          readOnly={true}
                          _disabled={{
                            border: "2px solid #E3E4E8",
                            opacity: selectedJorners ? 1 : 0.7,
                          }}
                          onClick={() => {
                            onOpenModalJorner();
                            onCloseCreateCommunity();
                          }}
                          focusBorderColor="#E3E4E8"
                          border={"1px solid #E3E4E8"}
                          height="50px"
                          name="strenths"
                          value={
                            selectedJorners
                              ? `${selectedJorners.first_name} ${selectedJorners.last_name}`
                              : ""
                          }
                          placeholder={
                            selectedJorners
                              ? `${selectedJorners.first_name} ${selectedJorners.last_name}`
                              : "Select"
                          }
                        />
                        <InputRightElement>
                          <ChevronDownIcon
                            mt="2"
                            fontSize={"22"}
                            color="gray.500"
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  )}
                  <FormControl isInvalid={errors.name} mt={3}>
                    <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                      Group Name
                    </FormLabel>
                    <Input
                      id="name"
                      name="name"
                      placeholder="e.g Jamie’s Care Circle Group"
                      {...register("name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.description} mt={3}>
                    <FormLabel
                      htmlFor="description"
                      fontWeight={600}
                      fontSize={14}
                    >
                      Description
                    </FormLabel>
                    {/* <Textarea
                      id="description"
                      name="description"
                      placeholder="e.g Simon"
                      {...register("description", {
                        required: "This is required",
                      })}
                    /> */}
                    <Textarea
                    onChange={(e)=> {
                      setDescription(e.target.value)
                    }}
                      id="description"
                      value={description}
                      placeholder="Type here..."
                      borderColor={description.length > 200 ? "red" : "#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                    <Box h={1} />
                  <Text color={description.length > 200 ? "red" : ""} fontSize={12}>{description.length}/200</Text>
                  </FormControl>
                  {!isEdit && (
                    <FormControl mt={3}>
                      <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                        Co-Caregiver
                      </FormLabel>
                      <InputGroup>
                        <Input
                          cursor={"pointer"}
                          readOnly={true}
                          _disabled={{
                            border: "2px solid #E3E4E8",
                            opacity:
                              selectedCaregiversName.length < 0 ? 1 : 0.7,
                          }}
                          onClick={() => {
                            onCloseCreateCommunity();
                            onOpenModalWho();
                          }}
                          focusBorderColor="#E3E4E8"
                          border={"1px solid #E3E4E8"}
                          height="50px"
                          name="ccg"
                          value={selectedCaregiversName.join(", ")}
                          placeholder={
                            selectedCaregiversName.length < 0
                              ? selectedCaregiversName.join(", ")
                              : "Select"
                          }
                        />
                        <InputRightElement>
                          <ChevronDownIcon
                            mt="2"
                            fontSize={"22"}
                            color="gray.500"
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  )}

                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {isEdit ? "Update Changes" : "Create Group"}
                  </Button>
                  <Text
                    textAlign={"center"}
                    color={"primary"}
                    textDecoration={"underline"}
                    fontSize={14}
                    fontWeight={600}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      onCloseCreateCommunity();
                    }}
                  >
                    Understand How Sojorne Care Circle Group Works
                  </Text>
                  <Box height={"20px"} />
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      }
    />
  );
}

export function TaskItem({ title, subtitle, onclick, img = "/cm1.png" }) {
  return (
    <Flex mt={5} gap={3} mb="32px" alignItems="start" cursor={"pointer"}>
      {/* <Avatar bg={"#FFF0F0"} icon={<BiSolidCoinStack color="#FF6E57" />} /> */}
      <Image src={img} h={"25px"} width={"25px"} objectFit={"contain"} />
      <Box>
        <Heading textAlign={"left"} fontSize={"18px"} fontWeight={600}>
          {title}
        </Heading>
        <Text mt={1} color={"paragraph"} fontSize="14px" fontWeight={400}>
          {subtitle}
        </Text>
      </Box>
    </Flex>
  );
}

export function CustomCheckBox({ item, allData, onSelectInterest }) {
  const [isSelected, setIsSelected] = React.useState(false);
  const onSelectInterestNow = (e) => {
    let newList = allData;
    if (newList.includes(e)) {
      const index = newList.indexOf(e);
      newList.splice(index, 1);
      onSelectInterest(newList);
      setIsSelected(false);
    } else {
      newList.push(e);
      onSelectInterest(newList);
      setIsSelected(true);
    }
  };

  return (
    <>
      <Box
        cursor={"pointer"}
        display={"flex"}
        border={"1px solid #EBECEF"}
        borderRadius={100}
        p={3}
        bg={isSelected ? "#F2FAFD" : "#FFFFFF"}
        color={isSelected ? "#1DA2C9" : "#000"}
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => onSelectInterestNow(item)}
        alignContent={"center"}
      >
        <Text mr={2}>{item}</Text>
        {isSelected ? <FaCheck /> : <FaPlus color="#747A8B" />}
      </Box>
    </>
  );
}
